import {
  Avatar,
  Button,
  Card,
  CardContent,
  CardHeader,
  Rating,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { StarRounded } from '@mui/icons-material'
import moment from 'moment'
import {
  arrowBgColor,
  headingColor,
  reviewBodyColor,
  reviewCardBorderColor,
  reviewSubColor,
  whiteColor,
} from '../../configs/styles/muiThemes'

// Card for review contnets display
function ReviewCard(props) {
  
  const matches = useMediaQuery((theme) => theme.breakpoints.up('md'))
  const contentLength = matches ? 130 : 90

  // const [ishelpFul, setIsHelpFul] = useState(false)
  // const [report, setReport] = useState(false)

  // /**
  //  * To update helpful reviews
  //  * @param reviewId
  //  */
  // const isHelpfulClicked = (reviewId, profileId) => {
  //   // for ui update
  //   setIsHelpFul(true)

  //   helpFulReview(reviewId, profileId)
  //     .then((res) => {
  //       props.getAllReviewDetails()
  //     })
  //     .catch((err) => {
  //     })
  // }

  // /**
  //  * To update abused reviews
  //  * @param reviewId
  //  */
  // const reportAbuseClicked = (reviewId) => {
  //   // for ui update
  //   setReport(true)

  //   reportAbuse(reviewId)
  //     .then((res) => {
  //       props.getAllReviewDetails()
  //     })
  //     .catch((err) => {})
  // }

  return (
    <Card
      sx={{
        textAlign: 'left',
        boxShadow: '0px 3px 7px rgb(0 0 0 / 8%)',
        borderRadius: '6px',
        border: '1px solid ' + reviewCardBorderColor,
        pb: 1,
      }}
    >
      <CardHeader
        avatar={
          <Avatar
            sx={{ width: 50, height: 50 }}
            alt='customer profile'
            src={
              props.showFullData
                ? props.data.profilePic
                : props.images[0]
                ? props.images[0].photoUrl
                : 'https://storage.googleapis.com/bodefaults/shopweb/review_avatar.png'
            }
          />
        }
        titleTypographyProps={cardStyles.headerTitle}
        title={props.data.name}
        subheaderTypographyProps={cardStyles.headerSub}
        subheader={`${props.totalReviews} Reviews | ${props.connection} Connections`}
        action={
          props.disableDate ? null : (
            <Typography variant='body1' color={reviewBodyColor}>
              {moment(props.data.updateDate).format('MMM DD, YYYY')}
            </Typography>
          )
        }
      />
      <CardContent
        sx={{
          textAlign: 'left',
          paddingTop: 0,
          paddingBottom: '0px !important',
        }}
      >
        <Rating
          readOnly
          icon={
            <StarRounded sx={{ color: 'primary.main', fontSize: '20px' }} />
          }
          emptyIcon={
            <StarRounded sx={{ color: arrowBgColor, fontSize: '20px' }} />
          }
          precision={0.1}
          value={props.data.reviewRating}
          sx={{ marginBottom: 1 }}
        />

        <Typography
          variant='h5'
          color={headingColor}
          mb='6px !important'
          fontSize={{ md: '15px', xs: '13px' }}
        >
          {props.data.reviewTitle}
        </Typography>

        <Typography
          variant='body1'
          color={reviewBodyColor}
          sx={{
            height: props.isShownInModal ? 'auto' : '55px',
            overflow: props.isShownInModal ? 'none' : 'hidden',
          }}
          fontSize={{ md: '13px', xs: '11px' }}
        >
          {props.isShownInModal && props.showFullData ? (
            props.data.message
          ) : (
            <Typography
              variant='body1'
              color={reviewBodyColor}
              sx={{
                height: props.isShownInModal ? 'auto' : '55px',
                overflow: props.isShownInModal ? 'none' : 'hidden',
              }}
              fontSize={{ md: '13px', xs: '11px' }}
            >
              {props.data.message.slice(0, contentLength)}

              {props.data.message.length > contentLength ? (
                <Button
                  onClick={() =>
                    props.onClickMore({
                      data: props.data,
                      totalReviews: props.totalReviews,
                      connection: props.connection,
                    })
                  }
                  sx={{
                    textTransform: 'none',
                    textDecoration: 'underline',
                    fontSize: '13px',
                  }}
                >
                  Click More
                </Button>
              ) : null}
            </Typography>
          )}
        </Typography>
      </CardContent>

      {/* <CardActions
        sx={{
          mx: 1,
          borderTop: borderBackground,
        }}
      >
        <Button
          startIcon={<ThumbUpAlt sx={{ fontSize: '14px' }} />}
          sx={{
            ...cardStyles.actionBtn,
            color: ishelpFul ? primaryColor : reviewSubColor,
          }}
          variant='text'
          size='small'
          onClick={() => isHelpfulClicked(props.data.id, props.data.profileId)}
        >
          {props.data.isHelpfulCount} Helpful
        </Button>

        <Button
          startIcon={<Info sx={{ fontSize: '14px' }} />}
          sx={{
            ...cardStyles.actionBtn,
            color: report ? primaryColor : reviewSubColor,
          }}
          variant='text'
          size='small'
          onClick={() => reportAbuseClicked(props.data.id)}
        >
          {props.data.abuseCount} Report Abuse!
        </Button>
      </CardActions> */}
    </Card>
  )
}

const cardStyles = {
  headerTitle: {
    fontSize: { md: '15px', xs: '14px' },
    color: headingColor,
    fontWeight: 600,
  },

  headerSub: {
    fontSize: '13px',
    fontWeight: 400,
    color: reviewSubColor,
    marginTop: '4px',
  },

  actionBtn: {
    fontSize: '14px',
    fontWeight: 400,
    textTransform: 'none',
    '&:hover': {
      backgroundColor: whiteColor,
    },
  },
}

export default ReviewCard

import {
  Box,
  Button,
  Container,
  Dialog,
  FormGroup,
  Grid,
  Typography,
} from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

import {
  labelColor,
  primaryColor,
  productLabelColor,
  whiteColor,
} from '../configs/styles/muiThemes'
import { useDispatch, useSelector } from 'react-redux'
import {
  getCatalogAllProducts,
  getCategoryDataFinder,
  getDataForSideBar,
} from 'redux/subCatergory/subCategorySlice'
import {
  getCartDetails,
  getCommercialDetails,
} from 'redux/addToCart/AddToCartSlice'
// import FilteredCategory from './CatalogMui/FilteredCategory'
// import FilteredSubCategory from './CatalogMui/FilteredSubCategory'

import {
  addLocalCart,
  addNewProductCart,
  updateCartProductQuantity,
  updateToLocalCart,
  createCart,
  removeProductLocalCart,
} from 'redux/cart/CartSlice'
import {
  createCartLoginHelper,
  removeCartProductLoginHelper,
} from 'utils/cartHelper'
import { getUserName, removeSessionInfo } from 'utils/authHelpers'
import SearchResult from './CatalogMui/SearchResult'
import { updateCartDetails } from 'redux/cart/CartSlice'
import { getBusinessIdDetails } from 'redux/rootSlice/RootSlice'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'
import TopComponent from './TopComponent'
import FooterMui from './../components/FooterMui'
import AllCategoryProducts from './CatalogMui/AllCategoryProducts'
import CatalogHeader from './CatalogMui/CatalogHeader'
import CategoryGroup from './CatalogMui/CategoryGroup'
import CheckBoxLabel from './CatalogMui/CheckBoxLabel'
import MainCategory from './CatalogMui/MainCategory'
import ProductFilterMobile from './CatalogMui/ProductFilterMobile'
import CartDetailsBottonCard from '../components/CartDetailsBottonCard'
import FilteredCategory from './CatalogMui/FilteredCategory'
import FilteredSubCategory from './CatalogMui/FilteredSubCategory'
import Loader from 'components/Loader'
import FullHeightLoader from 'components/FullHeightLoader'
import OfferPopupModal from 'components/OfferPopupModal/OfferPopupModal'

// const AllCategoryProducts = React.lazy(() =>import ('./CatalogMui/AllCategoryProducts'))
// const CatalogHeader = React.lazy(() =>import ('./CatalogMui/CatalogHeader'))
// const CategoryGroup = React.lazy(() =>import ('./CatalogMui/CategoryGroup'))
// const CheckBoxLabel = React.lazy(() =>import ('./CatalogMui/CheckBoxLabel'))
// const MainCategory = React.lazy(() =>import ('./CatalogMui/MainCategory'))
// const ProductFilterMobile = React.lazy(() =>import ('./CatalogMui/ProductFilterMobile'))
// const CartDetailsBottonCard = React.lazy(() =>import ('../components/CartDetailsBottonCard'))
// const FilteredCategory = React.lazy(() =>import ('./CatalogMui/FilteredCategory'))
// const FilteredSubCategory = React.lazy(() =>import ('./CatalogMui/FilteredSubCategory'))

function CatalogMui(props) {
  const { businessName } = useParams()
  const [searchParams] = useSearchParams()
  const atag = searchParams?.get('atag')

  const businessIdData = sessionStorage.getItem('businessId')

  const [viewByFilter, setViewByFilter] = useState('category')
  const [showFilters, setShowFilters] = useState(false)
  const dispatch = useDispatch()

  const [sort, SetSort] = useState('Newest First')
  const [discountHightToLow, setDiscountHighTOLow] = useState(false)
  const [lowPrice, setLowPrice] = useState(false)
  const [highPrice, setHighPrice] = useState(false)
  const [business, setBusiness] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  )

  const [totalQty, setTotalQty] = useState(0)

  const { productsByCategory, allProducts, category, loader } = useSelector(
    (state) => state.subCategory
  )
  const { cartDetails } = useSelector((state) => state.cart)
  const {
    updateQty,
    addedProduct,
    localCartData,
    createCartRes,
    updateCartIntRes,
  } = useSelector((state) => state.cartupdateadd)

  const { profile, loginDetails } = useSelector((state) => state.auth)

  const { searchData } = useSelector((state) => state.search)
  const searchElementRef = useRef(null)

  const [cartProductList, setCartProductList] = useState(null)

  // const businessId = sessionStorage.getItem('businessId')
  const { businessId } = useSelector((state) => state.rootSlice)
  const { commercialDetails } = useSelector((state) => state.product)

  const [filterProducts, setFilterProducts] = useState([])
  const [filteredSubCat, setFilteredSubCat] = useState([])

  const [pageLoader, setPageLoader] = useState(true)

  const location = useLocation()
  const [categorySideBar, setCategorySideBar] = useState(
    location?.state?.categorySideBar ? location?.state?.categorySideBar : null
  )
  // let categorySideBar = location?.state?.categorySideBar
  //   ? location?.state?.categorySideBar
  //   : null

  useEffect(() => {
    setCategorySideBar(
      location?.state?.categorySideBar ? location?.state?.categorySideBar : null
    )
  }, [location])

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem('businessName')
    if (businessName !== localBusinessName) {
      console.log('BUSINESS_NAME_CHANGED')
      removeSessionInfo()
      // window.location.replace(`/${businessName}/catalog`)
      sessionStorage.setItem('cartData', 'null')
    }

    dispatch(getBusinessIdDetails(businessName))
    sessionStorage.setItem('businessName', businessName)
  }

  const getProducts = () => {
    dispatch(
      getCatalogAllProducts({
        pageNo: pageNumber,
        discountHightToLow: discountHightToLow,
        priceLowoHig: lowPrice,
        highPrice: highPrice,
        business: business,
      })
    )
  }

  useEffect(() => {
    getBusinessData()
  }, [businessName])

  useEffect(() => {
    // if (businessIdData !== null) {
    if (businessId !== null) {
      dispatch(getDataForSideBar())
      dispatch(getCategoryDataFinder())
      if (!commercialDetails) {
        dispatch(getCommercialDetails())
      }
      if (isLoggedIn) {
        if (!cartDetails) {
          dispatch(getCartDetails(businessId))
        }
      }
      getProducts()
    }

    return () => {
      // dispatch(clearSearchData())
    }
  }, [businessId])

  useEffect(() => {
    if (loginDetails) {
      dispatch(getCartDetails(businessId))
      setIsLoggedIn(true)
    }
  }, [loginDetails])

  const getCartItemCount = () => {
    let count = 0
    const cartProductData = JSON.parse(sessionStorage.getItem('cartData'))
    cartProductData?.productList?.forEach((item) => {
      count = count + item.currentQuantity
    })
    setTotalQty(count)
    setCartProductList(cartProductData)
  }

  useEffect(() => {
    getCartItemCount()
  }, [cartDetails, updateQty, addedProduct, createCartRes])

  const handleProductCatClick = (subCat) => {
    let filteredArray = allProducts?.productList?.filter((item, index) => {
      return item?.productSubCategory?.[0]?.code == subCat?.code
    })

    setFilterProducts({ products: filteredArray, title: subCat?.value })

    setFilteredSubCat([])
    setViewByFilter('filtered-products')
  }

  const handleSubCatClick = (catCode) => {
    const isPresent = filteredSubCat.filter((item, index) => {
      return item.code === catCode.code
    })
    if (isPresent.length > 0) {
      if (filteredSubCat.length === 1) {
        setFilterProducts({ products: [], title: '' })
        setFilteredSubCat([])
        setViewByFilter('category')
        return
      }

      const newList = filteredSubCat.filter((item, index) => {
        return item.code !== catCode.code
      })
      setFilteredSubCat(newList)
    } else {
      setFilteredSubCat([...filteredSubCat, catCode])
    }

    setFilterProducts({ products: [], title: '' })
    setViewByFilter('filtered-subcat')
  }

  const onRemoveSubCatFilter = (subcat) => {
    const filteredList = filteredSubCat.filter((item) => {
      return item.code !== subcat.code
    })

    if (filteredSubCat.length === 1) {
      setFilterProducts({ products: [], title: '' })
      setFilteredSubCat([])
      setViewByFilter('category')

      return
    }
    setFilteredSubCat(filteredList)
  }

  const onSideBarCatSelected = (subCat) => {
    console.log('CATALOG_MUI__:')
    let filteredArray = allProducts?.productList?.filter((item, index) => {
      return item?.productSubCategory?.[0]?.code == subCat?.subcatcode
    })

    console.log('CATALOG_MUI__:', filteredArray)

    setFilterProducts({ products: filteredArray, title: subCat?.subCatValue })

    setFilteredSubCat([])
    setViewByFilter('filtered-products')
    setPageLoader(false)
  }

  const addtoLocalCart = (item) => {
    dispatch(addLocalCart(item))
  }

  const updateLocalCart = (item) => {
    dispatch(updateToLocalCart(item))
  }

  useEffect(() => {
    if (localCartData) {
      setCartProductList(localCartData)
      setTotalQty(localCartData?.totalQuantity)
    }
  }, [localCartData])

  useEffect(() => {
    if (categorySideBar && allProducts) {
      onSideBarCatSelected(categorySideBar)
    }
    return () => {
      setCategorySideBar(null)
    }
  }, [categorySideBar, allProducts])

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getCartDetails(businessId)).then((carRes) => {
        console.log('SESSION_CART_DATA_Cat:', carRes?.payload?.detail)
        sessionStorage.setItem(
          'cartData',
          JSON.stringify(carRes?.payload?.detail || null)
        )
        setCartProductList(carRes?.payload?.detail)
      })
    }
  }, [addedProduct, createCartRes, updateCartIntRes])

  const onAddProductToCart = (data) => {
    if (isLoggedIn) {
      addProductLoading(data?.product?.productId)
      const cartData = JSON.parse(sessionStorage.getItem('cartData'))
      if (cartData) {
        dispatch(addNewProductCart(data))
      } else {
        const postData = createCartLoginHelper(data)
        dispatch(createCart(postData))
      }
    } else {
      addtoLocalCart(data?.product)
    }
  }

  const removeProductFromCart = (data) => {
    if (isLoggedIn) {
      addProductLoading(data?.productId)
      const postData = removeCartProductLoginHelper(data.productId)
      dispatch(updateCartDetails({ ...postData, id: cartDetails?.cartId }))
    } else {
      dispatch(removeProductLocalCart({ productId: data.productId }))
    }
  }

  const addProductLoading = (productId) => {
    let isPresent = false
    cartProductList?.productList?.forEach((item) => {
      if (item.productId == productId) {
        item.loading = true
        isPresent = true
      }
    })

    if (!isPresent) {
      cartProductList?.productList?.push({
        loading: true,
        productId: productId,
      })
    }
  }

  useEffect(() => {
    if (searchData) {
      setViewByFilter('search')
      setPageLoader(false)
      // window.scrollTo(0, searchElementRef.current.offsetTop)
    }
  }, [searchData])

  const [scrolled, setScrolled] = React.useState(false)
  const handleScroll = () => {
    const offset = window.scrollY
    if (offset > 200) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  // If business as only one product category then show all products
  useEffect(() => {
    if (
      category?.length > 0 &&
      allProducts?.productList?.length > 0 &&
      viewByFilter === 'category'
    ) {
      if (category?.length === 1 && category?.[0]?.subCat?.length === 1) {
        // setViewByFilter('all-products')
        handleProductCatClick(category?.[0]?.subCat?.[0])
        setPageLoader(false)
      } else {
        setPageLoader(false)
      }
    }
    setPageLoader(false)
    return () => {}
  }, [category, allProducts])

  return (
    <>
      {/* businessIdData : {JSON.stringify(businessIdData !== null)} <br />
      commerce : {JSON.stringify(commercialDetails !== null)} <br />
      allProducts : {JSON.stringify(allProducts !== null)} <br />
      productsByCategory : {JSON.stringify(productsByCategory?.length > 0)}{' '}
      <br />
      category.length : {JSON.stringify(category?.length > 0)} <br />
      !pageLoader : {JSON.stringify(!pageLoader)} <br />
      {businessIdData !== null &&
      commercialDetails !== null &&
      allProducts !== null &&
      productsByCategory?.length > 0 &&
      category?.length > 0 &&
      !pageLoader ? (
        <b>DONE</b>
      ) : (
        <b>Loading...</b>
      )} */}
      {businessIdData !== null &&
      commercialDetails !== null &&
      allProducts !== null ? (
        <>
          <Box
            sx={{
              position: 'sticky',
              top: 0,
              width: '100%',
              zIndex: 100,
            }}
          >
            <TopComponent value={'catalog'} disableBanners />
            <Container sx={{ maxWidth: '1300px !important' }}>
              <CatalogHeader
                onFilterClick={() => {
                  setShowFilters(true)
                }}
                itemCount={totalQty}
                totalPrice={
                  cartProductList ? cartProductList.orderTotalProductCost : 0
                }
              />
            </Container>
          </Box>
        </>
      ) : null}
      {businessIdData !== null &&
      commercialDetails !== null &&
      allProducts !== null &&
      productsByCategory?.length > 0 &&
      // category?.length > 0 &&
      !pageLoader ? (
        <>
          <Box>
            <Container sx={{ maxWidth: '1300px !important' }}>
              {/* <Divider light /> */}

              {/* ---------Empty Catalog card--------- */}
              <Grid container spacing={3.5}>
                <Grid item xs={0} md={3} display={{ xs: 'none', md: 'block' }}>
                  <Dialog
                    fullScreen
                    display={{ md: 'none', xs: 'block' }}
                    open={showFilters}
                    scroll='paper'
                    onClose={() => {
                      setShowFilters(false)
                    }}
                  >
                    <ProductFilterMobile
                      onCloseClick={() => {
                        setShowFilters(false)
                      }}
                      onFilterClick={(value) => {
                        if (value.type === 'all-products') {
                          setViewByFilter('all-products')
                        }
                        if (value.type === 'filtered-subcat') {
                          if (value.filteredSubCat?.length > 0) {
                            setFilteredSubCat(value.filteredSubCat)
                            setViewByFilter('filtered-subcat')
                          } else {
                            let filteredArray =
                              allProducts?.productList?.filter(
                                (item, index) => {
                                  return (
                                    item?.productSubCategory?.[0]?.code ==
                                    value?.filteredProduct?.code
                                  )
                                }
                              )

                            setFilterProducts({
                              products: filteredArray,
                              title: value?.filteredProduct?.value,
                            })

                            setFilteredSubCat([])
                            setViewByFilter('filtered-products')
                          }
                        }
                        if (value.type === 'filtered-products') {
                          let filteredArray = allProducts?.productList?.filter(
                            (product, index) => {
                              return (
                                product?.productSubCategory?.[0]?.pcCode ==
                                value?.filteredProduct?.code
                              )
                            }
                          )
                          setFilterProducts({
                            products: filteredArray,
                            title: value?.filteredProduct?.value,
                          })

                          setFilteredSubCat([])
                          setViewByFilter('filtered-products')
                        }
                        if (value.type === '') {
                          setViewByFilter('category')
                        }
                        setShowFilters(false)
                      }}
                      category={category}
                    />
                  </Dialog>

                  {category && category.length > 0 && (
                    <Box mt={1.2}>
                      <Typography
                        variant='body1'
                        color={productLabelColor}
                        fontSize='14px'
                        fontWeight={600}
                        sx={{ cursor: 'pointer', py: 1 }}
                        onClick={() => {
                          setFilterProducts({ products: [], title: '' })
                          setFilteredSubCat([])
                          setViewByFilter('all-products')
                        }}
                      >
                        All Products
                      </Typography>
                      {category.map((item, index) => {
                        return (
                          <Box>
                            <MainCategory
                              label={item.value}
                              productCount={item?.productCount}
                              titleColor={
                                item?.value === filterProducts?.title
                                  ? primaryColor
                                  : productLabelColor
                              }
                              catCode={item}
                              onViewMore={(subCat) => {
                                let filteredArray =
                                  allProducts?.productList?.filter(
                                    (product, index) => {
                                      return (
                                        product?.productSubCategory?.[0]
                                          ?.pcCode === item?.code
                                      )
                                    }
                                  )

                                setFilterProducts({
                                  products: filteredArray,
                                  title: item?.value,
                                })

                                setFilteredSubCat([])
                                setViewByFilter('filtered-products')
                              }}
                            />
                            {item.subCat.map((subcat, index) => {
                              return (
                                <Box ml={1}>
                                  <MainCategory
                                    isSubCatagory
                                    label={subcat.value}
                                    productCount={subcat?.productCount}
                                    titleColor={
                                      subcat?.value === filterProducts?.title
                                        ? primaryColor
                                        : productLabelColor
                                    }
                                    subCat={subcat}
                                    onViewMore={(subCat) => {
                                      handleProductCatClick(subCat)
                                    }}
                                  />
                                  {subcat.subCat2.map((subcat2, index) => {
                                    return (
                                      <FormGroup>
                                        <CheckBoxLabel
                                          // label={subcat2.value}
                                          label={
                                            <Typography
                                              variant='body1'
                                              color={labelColor}
                                              fontSize='13px'
                                              fontWeight={400}
                                            >
                                              {subcat2.value}
                                            </Typography>
                                          }
                                          subCategory={subcat2}
                                          checked={filteredSubCat.includes(
                                            subcat2
                                          )}
                                          onValuesChanged={(catCode) => {
                                            handleSubCatClick(catCode)
                                          }}
                                        ></CheckBoxLabel>
                                      </FormGroup>
                                    )
                                  })}
                                </Box>
                              )
                            })}
                          </Box>
                        )
                      })}
                    </Box>
                  )}
                </Grid>

                <Grid item xs={12} md={9}>
                  <Grid container spacing={1} mt={1.4}>
                    <Grid item>
                      <Button
                        sx={[
                          styles.optionBtn,
                          {
                            backgroundColor:
                              viewByFilter === 'category' ||
                              (category?.length === 1 &&
                                category?.[0]?.subCat?.length === 1 &&
                                viewByFilter === 'filtered-products')
                                ? 'primary'
                                : whiteColor,
                            color:
                              viewByFilter === 'category' ||
                              (category?.length === 1 &&
                                category?.[0]?.subCat?.length === 1 &&
                                viewByFilter === 'filtered-products')
                                ? whiteColor
                                : primaryColor,
                          },
                        ]}
                        variant='contained'
                        // color={viewByFilter === 'category' ? 'primary' : 'inherit'}
                        disableElevation
                        size='large'
                        onClick={() => {
                          if (
                            category?.length === 1 &&
                            category?.[0]?.subCat?.length === 1
                          ) {
                            handleProductCatClick(category?.[0]?.subCat?.[0])
                            return
                          }

                          setFilterProducts({ products: [], title: '' })
                          setFilteredSubCat([])
                          setViewByFilter('category')
                        }}
                      >
                        View By Category
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        sx={[
                          styles.optionBtn,
                          {
                            backgroundColor:
                              viewByFilter === 'all-products'
                                ? 'primary'
                                : whiteColor,
                            color:
                              viewByFilter === 'all-products'
                                ? whiteColor
                                : primaryColor,
                          },
                        ]}
                        variant='contained'
                        disableElevation
                        size='large'
                        onClick={() => {
                          setFilterProducts({ products: [], title: '' })
                          setFilteredSubCat([])
                          setViewByFilter('all-products')
                        }}
                      >
                        View All Products
                      </Button>
                    </Grid>
                  </Grid>

                  {viewByFilter === 'search' ? (
                    <Box component='div' ref={searchElementRef}>
                      <SearchResult
                        searchProducts={searchData?.details}
                        cartData={cartProductList?.productList}
                        cartId={cartProductList?.cartId}
                        allProducts={allProducts?.productList}
                        onQtyUpdate={(data) => {
                          if (isLoggedIn) {
                            addProductLoading(data?.productId)
                            dispatch(updateCartProductQuantity(data))
                          } else {
                            updateLocalCart(data)
                          }
                        }}
                        onAddProduct={(data) => {
                          // addProductLoading(data?.product?.productId)
                          onAddProductToCart(data)
                        }}
                        onRemoveProduct={(data) => {
                          // addProductLoading(data?.productId)
                          removeProductFromCart(data)
                        }}
                      />
                    </Box>
                  ) : null}

                  {viewByFilter === 'filtered-products' ? (
                    <FilteredCategory
                      selectedCategory={filterProducts}
                      cartData={cartProductList?.productList}
                      cartId={cartProductList?.cartId}
                      onQtyUpdate={(data) => {
                        if (isLoggedIn) {
                          addProductLoading(data?.productId)
                          dispatch(updateCartProductQuantity(data))
                        } else {
                          updateLocalCart(data)
                        }
                      }}
                      onAddProduct={(data) => {
                        // addProductLoading(data?.product?.productId)
                        onAddProductToCart(data)
                      }}
                      onRemoveProduct={(data) => {
                        // addProductLoading(data?.productId)
                        removeProductFromCart(data)
                      }}
                    />
                  ) : null}

                  {viewByFilter === 'filtered-subcat' ? (
                    <FilteredSubCategory
                      allProducts={allProducts}
                      filteredSubCat={filteredSubCat}
                      onRemoveSubCatFilter={onRemoveSubCatFilter}
                      cartData={cartProductList?.productList}
                      cartId={cartProductList?.cartId}
                      onQtyUpdate={(data) => {
                        if (isLoggedIn) {
                          addProductLoading(data?.productId)
                          dispatch(updateCartProductQuantity(data))
                        } else {
                          updateLocalCart(data)
                        }
                      }}
                      onAddProduct={(data) => {
                        // addProductLoading(data?.product?.productId)
                        onAddProductToCart(data)
                      }}
                      onRemoveProduct={(data) => {
                        // addProductLoading(data?.productId)
                        removeProductFromCart(data)
                      }}
                    />
                  ) : null}

                  {viewByFilter === 'category' ? (
                    <Box>
                      {loader && (
                        <Grid
                          item
                          container
                          direction='column'
                          xs={12}
                          justify='center'
                          alignContent='center'
                          alignItems='center'
                        >
                          <Grid item>
                            {/* <CircularProgress></CircularProgress> */}
                            <Loader />
                          </Grid>
                        </Grid>
                      )}
                      {loader === false &&
                        productsByCategory &&
                        productsByCategory.length > 0 && (
                          <Box>
                            {productsByCategory.map((item, index) => {
                              return (
                                <CategoryGroup
                                  key={`view_by_cat_group_${index}`}
                                  title={item.subCatValue}
                                  subCat={item}
                                  products={item.products}
                                  count={item.count}
                                  onViewMore={(catCode) => {
                                    handleProductCatClick({
                                      code: catCode,
                                      value: item?.subCatValue,
                                    })
                                  }}
                                  cartData={cartProductList?.productList}
                                  cartId={cartProductList?.cartId}
                                  onQtyUpdate={(data) => {
                                    if (isLoggedIn) {
                                      addProductLoading(data?.productId)
                                      dispatch(updateCartProductQuantity(data))
                                    } else {
                                      updateLocalCart(data)
                                    }
                                  }}
                                  onAddProduct={(data) => {
                                    // addProductLoading(data?.product?.productId)
                                    onAddProductToCart(data)
                                  }}
                                  onRemoveProduct={(data) => {
                                    // addProductLoading(data?.productId)
                                    removeProductFromCart(data)
                                  }}
                                  // showInGrid={
                                  //   category?.length === 1 &&
                                  //   category?.[0]?.subCat?.length === 1
                                  // }
                                />
                              )
                            })}
                          </Box>
                        )}
                    </Box>
                  ) : null}

                  {viewByFilter === 'all-products' ? (
                    <Box>
                      {loader && (
                        <Grid
                          item
                          container
                          direction='column'
                          xs={12}
                          justify='center'
                          alignContent='center'
                          alignItems='center'
                        >
                          <Grid item>
                            {/* <CircularProgress></CircularProgress> */}
                            <Loader />
                          </Grid>
                        </Grid>
                      )}
                      {loader === false &&
                        allProducts &&
                        allProducts.productList &&
                        allProducts.productList.length > 0 && (
                          <AllCategoryProducts
                            producList={allProducts.productList}
                            totalCount={allProducts.totalProduct}
                            pageNo={allProducts.pageNo}
                            onClickNextPage={(page) => {
                              setPageNumber(page)
                              dispatch(
                                getCatalogAllProducts({
                                  pageNo: page,
                                  discountHightToLow: discountHightToLow,
                                  priceLowoHig: lowPrice,
                                  highPrice: highPrice,
                                  business: business,
                                })
                              )
                            }}
                            cartData={cartProductList}
                            onQtyUpdate={(data) => {
                              if (isLoggedIn) {
                                addProductLoading(data?.productId)
                                dispatch(updateCartProductQuantity(data))
                              } else {
                                updateLocalCart(data)
                              }
                            }}
                            onAddProduct={(data) => {
                              // addProductLoading(data?.product?.productId)
                              onAddProductToCart(data)
                            }}
                            onRemoveProduct={(data) => {
                              // addProductLoading(data?.productId)
                              removeProductFromCart(data)
                            }}
                            onClickSort={(optionData) => {
                              if (optionData === 'Discount - High To Low') {
                                setDiscountHighTOLow(true)
                                SetSort(optionData)
                                // getProducts()
                                setCategorySideBar(null)
                                dispatch(
                                  getCatalogAllProducts({
                                    pageNo: pageNumber,
                                    discountHightToLow: true,
                                    priceLowoHig: false,
                                    highPrice: false,
                                    business: false,
                                  })
                                )
                              }
                              if (optionData === 'Price - Low To High') {
                                SetSort(optionData)
                                setLowPrice(true)
                                setCategorySideBar(null)
                                dispatch(
                                  getCatalogAllProducts({
                                    pageNo: pageNumber,
                                    discountHightToLow: false,
                                    priceLowoHig: true,
                                    highPrice: false,
                                    business: false,
                                  })
                                )
                              }
                              if (optionData === 'Price - High To Low') {
                                SetSort(optionData)
                                setHighPrice(true)
                                setCategorySideBar(null)
                                dispatch(
                                  getCatalogAllProducts({
                                    pageNo: pageNumber,
                                    discountHightToLow: false,
                                    priceLowoHig: false,
                                    highPrice: true,
                                    business: false,
                                  })
                                )
                              }
                              if (optionData === 'Newest First') {
                                SetSort(optionData)
                                setBusiness(false)
                                setCategorySideBar(null)
                                dispatch(
                                  getCatalogAllProducts({
                                    pageNo: pageNumber,
                                    discountHightToLow: false,
                                    priceLowoHig: false,
                                    highPrice: false,
                                    business: false,
                                  })
                                )
                              }
                            }}
                            optionData={sort}
                          />
                        )}
                    </Box>
                  ) : null}
                </Grid>
              </Grid>

              {totalQty > 0 ? (
                <CartDetailsBottonCard
                  amount={
                    cartProductList ? cartProductList.orderTotalProductCost : 0
                  }
                  cartProductList={cartProductList}
                  itemCount={totalQty}
                />
              ) : null}
            </Container>

            {loader === false &&
              productsByCategory &&
              productsByCategory.length > 0 && (
                <>
                  <FooterMui />
                  <OfferPopupModal couponCode={atag} />
                </>
              )}
          </Box>
        </>
      ) : (
        <FullHeightLoader />
      )}
    </>
  )
}

export default CatalogMui

const styles = {
  optionBtn: {
    fontSize: '13px',
    fontWeight: 500,
    color: 'primary',
    p: '3px 15px',
    textTransform: 'none',
    borderRadius: '5px',
    border: '1px solid ' + primaryColor,
    '&:hover': {
      backgroundColor: 'primary.main',
      color: 'white',
    },
  },
}

import { useEffect, useRef, useState } from "react";
import FooterMui from "../components/FooterMui";
import CartContent from "./Cart/CartContent";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCartDetails,
  getCartDetailsById,
  getCommercialDetails,
  getOfferOrderDetails,
} from "redux/addToCart/AddToCartSlice";
import {
  checkCartOrderProducts,
  removeProductLocalCart,
  updateCartProductPrice,
  updateCartProductQuantity,
  updateCartProducts,
  updateToLocalCart,
} from "redux/cart/CartSlice";
import { getRewardPointsDetails } from "redux/checkout/CheckoutSlice";
import AlertDialog from "../components/AlertDialog";
import TopComponent from "./TopComponent";
import { getUserName } from "utils/authHelpers";
import EmptyCard from "components/EmptyCard";
import {
  cardEmptyBtnText,
  cardEmptyDescriptionMsg,
  cardEmptyGuestBtnText,
  cardEmptyGuestDescriptionMsg,
  cardEmptyGuestImgUrl,
  cardEmptyGuestMsg,
  cardEmptyImgUrl,
  cardEmptyMsg,
  orderAmtLimitErrorMsg,
  orderLimitAmount,
} from "configs/Constants";
import { getCatalogAllProducts } from "redux/subCatergory/subCategorySlice";
import { Box, Container, Typography } from "@mui/material";
import BreadcrumbsSection from "components/Breadcrumbs";
import { headingColor, whiteColor } from "configs/styles/muiThemes";
import { getBusinessIdDetails } from "redux/rootSlice/RootSlice";
import axios from "axios";

function Cart() {
  const { businessName } = useParams();
  const dispatch = useDispatch();
  // const businessId = sessionStorage.getItem('businessId')
  // const businessName = sessionStorage.getItem('businessName')
  const [isLoggedIn, setIsLoggedIn] = useState(
    getUserName().length === 0 ? false : true
  );
  // const cartId = "3491862fe9f546e3ad4d2c82e48fb8ff"
  const cartId = null;
  const { cartDetails, commercialDetails, loading } = useSelector(
    (state) => state.cart
  );
  const {
    updateQty,
    checkOrderProducts,
    updateCartRes,
    updatePriceRes,
    localCartData,
  } = useSelector((state) => state.cartupdateadd);

  const { allProducts } = useSelector((state) => state.subCategory);

  let navigate = useNavigate();

  const { loginDetails } = useSelector((state) => state.auth);

  const [cartProductList, setCartProductList] = useState(null);
  const [cartProductOfferCheckList, setCartProductOfferCheckList] = useState(
    []
  );

  const [cashbackData, setCashbackData] = useState(null);
  const { rewardpoints } = useSelector((state) => state.checkout);

  const [validateCart, setValidateCart] = useState(false);

  const [alertMsg, setAlertMsg] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);

  const [showLogin, setShowLogin] = useState(false);
  const errorCount = useRef(0);

  const { businessId } = useSelector((state) => state.rootSlice);

  const getBusinessData = () => {
    const localBusinessName = sessionStorage.getItem("businessName");
    if (businessName !== localBusinessName) {
      sessionStorage.setItem("cartData", "null");
    }

    dispatch(getBusinessIdDetails(businessName));
    sessionStorage.setItem("businessName", businessName);
  };

  useEffect(() => {
    getBusinessData();
  }, [businessName]);

  useEffect(() => {
    if (businessId?.validate) {
      console.log("Valid_BusinessID");
      if (cartId) {
        dispatch(getCartDetailsById(cartId));
      } else {
        if (isLoggedIn) {
          dispatch(getCartDetails(businessId));
        }
      }

      dispatch(getCommercialDetails());
      dispatch(getCatalogAllProducts());
    }
  }, [businessId]);

  //Offer Order Check
  const checkOfferOrder = async () => {
    let cartProductData = [];

    for (let i = 0; i < cartDetails?.productList?.length; i++) {
      const filterItem = checkOrderProducts?.filter(
        (item) => item.id == cartDetails?.productList?.[i]?.productId
      );

      if (filterItem.length > 0) {
        if (filterItem?.[0]?.offer === "promo") {
          const res = await dispatch(
            getOfferOrderDetails(cartDetails?.productList?.[i]?.productId)
          );

          cartProductData.push({
            ...cartDetails?.productList?.[i],
            offerOrderPresent: res?.payload?.present,
          });
        } else {
          cartProductData.push({
            ...cartDetails?.productList?.[i],
            offerOrderPresent: false,
          });
        }
      }
    }

    setCartProductOfferCheckList(cartProductData);
  };

  useEffect(() => {
    if (
      cartDetails?.productList?.length > 0 &&
      checkOrderProducts?.length > 0
    ) {
      checkOfferOrder();
    }
  }, [cartDetails, checkOrderProducts]);

  const handleErrorCount = () => {
    errorCount.current = errorCount.current + 1;
  };

  // useEffect(() => {
  //   if (cartId) {
  //     dispatch(getCartDetailsById(cartId))
  //   } else {
  //     if (isLoggedIn) {
  //       dispatch(getCartDetails(businessId))
  //     }
  //   }

  //   dispatch(getCommercialDetails())
  //   dispatch(getCatalogAllProducts())
  // }, [])

  useEffect(() => {
    if (businessId?.validate) {
      if (isLoggedIn) {
        dispatch(getCartDetails(businessId)).then((carRes) => {
          sessionStorage.setItem(
            "cartData",
            JSON.stringify(carRes?.payload?.detail)
          );
        });
      }
    }
  }, [updateQty, updateCartRes, updatePriceRes, businessId]);

  const getCartItemCount = () => {
    const cartProductData = JSON.parse(sessionStorage.getItem("cartData"));
    setCartProductList(cartProductData);
  };

  useEffect(() => {
    if (businessId?.validate) {
      if (cartDetails) {
        dispatch(checkCartOrderProducts(cartDetails?.productList));
      }
    }
  }, [cartDetails, businessId]);

  useEffect(() => {
    getCartItemCount();
  }, [cartDetails]);

  useEffect(() => {
    if (businessId?.validate) {
      const cashback = JSON.parse(sessionStorage.getItem("cashback"));
      if (!cashback) {
        dispatch(getRewardPointsDetails());
      } else {
        setCashbackData(cashback);
      }
    }
  }, [rewardpoints, businessId]);

  useEffect(() => {
    if (localCartData) {
      setCartProductList(localCartData);
      // setTotalQty(localCartData?.totalQuantity)
    }
  }, [localCartData]);

  useEffect(() => {
    if (businessId?.validate) {
      if (loginDetails) {
        dispatch(getCartDetails(businessId));
        setIsLoggedIn(true);
      }
    }
  }, [loginDetails, businessId]);

  const validateCartItems = () => {
    if (getUserName().length === 0) {
      // setShowLogin(!showLogin)
      setShowLogin(showLogin + 1);
      return;
    }

    if (commercialDetails?.info?.commerceEnable == false) {
      return;
    }

    // Validation to check if order total is less than minOrderAmount
    if (
      cartProductList?.orderTotalProductCost <
      commercialDetails?.info?.orderInfoDetails?.[0]?.minOrderAmount
    ) {
      setAlertMsg(
        `Order total should be more than ₹${commercialDetails?.info?.orderInfoDetails?.[0]?.minOrderAmount}`
      );
      setAlertOpen(true);
      return;
    }

    // Validation to check if order total is more than 500,000
    if (cartProductList?.orderTotalProductCost > orderLimitAmount) {
      setAlertMsg(orderAmtLimitErrorMsg);
      setAlertOpen(true);
      return;
    }

    dispatch(getCartDetails(businessId));
    setValidateCart(true);

    if (errorCount.current == 0) {
      navigate(`/${businessName}/DiscountDelivery`);
    }
  };

  return (
    <Box>
      <Box
        sx={{
          // position: { md: 'sticky', xs: 'static' },
          position: "sticky",
          top: 0,
          width: "100%",
          zIndex: 100,
          backgroundColor: whiteColor,
          pb: 1.5,
        }}
      >
        <TopComponent value="" disableBanners showLoginForm={showLogin} />

        <Box display={{ xs: "none", md: "block" }}>
          <CartHeaderBreadcrums />
        </Box>
      </Box>

      <Box display={{ xs: "block", md: "none" }} pb={1.5}>
        <CartHeaderBreadcrums />
      </Box>
      {/* {!loading && cartProductList && commercialDetails && checkOrderProducts && ( */}

      {!loading &&
        cartProductList?.productList?.length > 0 &&
        // cartProductOfferCheckList.length > 0 &&
        commercialDetails &&
        checkOrderProducts && (
          <CartContent
            cartDetails={cartProductList}
            productOfferCheckList={cartProductOfferCheckList}
            commercialDetails={commercialDetails}
            checkOrderProducts={checkOrderProducts}
            onQtyUpdate={(data) => {
              if (isLoggedIn) {
                dispatch(updateCartProductQuantity(data));
              } else {
                dispatch(updateToLocalCart(data));
              }
              errorCount.current = 0;
            }}
            cashbackData={cashbackData}
            onProceed={validateCartItems}
            validateCart={validateCart}
            onRemoveProduct={(cartData, orderProductData, selectedProduct) => {
              if (isLoggedIn) {
                dispatch(
                  updateCartProducts({
                    cartData,
                    orderProductData,
                    selectedProduct,
                  })
                );
              } else {
                console.log("selectedProduct", selectedProduct);
                dispatch(
                  removeProductLocalCart({
                    productId: selectedProduct.productId,
                  })
                );
              }

              errorCount.current = 0;
            }}
            onPriceUpdate={(cartData, orderProductData, selectedProduct) => {
              dispatch(
                updateCartProductPrice({
                  cartData,
                  orderProductData,
                  selectedProduct,
                })
              );
              errorCount.current = 0;
            }}
            commerceDisabled={commercialDetails?.info?.commerceEnable == false}
            errorCount={errorCount}
            handleErrorCount={handleErrorCount}
            allProducts={allProducts}
          />
        )}
      {(cartProductList?.productList?.length == 0 || !cartProductList) &&
      isLoggedIn ? (
        <EmptyCard
          message={cardEmptyMsg}
          description={cardEmptyDescriptionMsg}
          imageUrl={cardEmptyImgUrl}
          actionBtnText={cardEmptyBtnText}
        />
      ) : null}
      {(cartProductList?.productList?.length == 0 || !cartProductList) &&
      !isLoggedIn ? (
        <EmptyCard
          message={cardEmptyGuestMsg}
          description={cardEmptyGuestDescriptionMsg}
          imageUrl={cardEmptyGuestImgUrl}
          type={cardEmptyGuestBtnText}
          onTypeClick={() => setShowLogin(!showLogin)}
        />
      ) : null}
      {/* Cart Empty */}
      {/* Cart Empty Guest */}
      <AlertDialog
        content={alertMsg}
        isOpen={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
      />
      <FooterMui />
    </Box>
  );
}

export default Cart;

const CartHeaderBreadcrums = () => {
  return (
    <Container
      sx={{
        maxWidth: "1300px !important",
        mt: { xs: 0.5, md: 2.5 },
      }}
    >
      <BreadcrumbsSection
        links={[
          { label: "Catalog", to: `/catalog` },
          { label: "Cart", to: `/cart` },
        ]}
      />

      <Typography
        variant="subtitle1"
        component="h5"
        fontSize={{ xs: 15, md: 19 }}
        fontWeight={600}
        color={headingColor}
        mt={0.3}
        ml={0.1}
        py={0.3}
        // display={{xs:'block', md:'none'}}
      >
        Cart Details
      </Typography>
    </Container>
  );
};

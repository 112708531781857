import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material'
import AuthTextBox from 'components/AuthTextBox'
import {
  enterPasswordMsg,
  inputError_OTPIncorrent,
  LATITUDE,
  LONGITUDE,
} from 'configs/Constants'
import {
  borderOTPTextbox,
  errorTextColor,
  headingColor,
  labelColor,
  loginPrimary,
  primaryColor,
  secondaryColor,
  stepperIconColor,
  whiteColor,
} from 'configs/styles/muiThemes'
import React, { useState } from 'react'
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded'
import { Link } from 'react-router-dom'
import OtpInput from 'react-otp-input'
import { useDispatch } from 'react-redux'
import firebase from '../../../firebase'
import { checkExistingUserGuest } from 'redux/authRedux/authSlice'
import {
  createGuestUser,
  getOwnRewards,
  upadateRewardProfile,
  userAuthGuestUser,
} from 'services/api_calls/auth/login_api'

function OTPScreen({
  mobileNumber,
  onLoginSuccess,
  onChangeNumber,
  onCreateAccount,
  userType,
}) {
  const [otp, setOtp] = useState('')
  const [errorMsg, setErrorMsg] = useState(false)
  const [inputErrorMsg, setInputErrorMsg] = useState(false)
  // const [token, setToken] = useState('')
  let businessFullName = sessionStorage.getItem('businessFullName')

  const [loading, setLoading] = useState(false)

  const dispatch = useDispatch()

  // const configureCaptcha = () => {
  //   window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
  //     'sign-in-button',
  //     {
  //       size: 'invisible',
  //       callback: (response) => {
  //         // reCAPTCHA solved, allow signInWithPhoneNumber.
  //         onSignInSubmit()
  //         console.log('Recaptca varified')
  //       },
  //       defaultCountry: 'IN',
  //     }
  //   )
  // }

  const onSignInSubmit = (type) => {
    setErrorMsg(false)
    setInputErrorMsg(false)
    if (otp.length != 6) {
      setInputErrorMsg(true)
      setLoading(false)
      return
    }
    setLoading(true)
    console.log(window)
    window.confirmationResult
      .confirm(otp)
      .then((result) => {
        const user = result.user
        console.log(JSON.stringify(user))
        console.log(result)
        let x = firebase
          .auth()
          .currentUser.getIdToken(true)
          .then(function (idToken) {
            // console.log('Otp verified')
            //   onRegisterClick(idToken, guest)
            if (type === 'guest') {
              checkUser(mobileNumber, idToken)
            } else {
              onCreateAccount(idToken)
            }

            // return idToken
          })

          .catch(function (error) {
            setLoading(false)
          })

        // ...
      })
      .catch((error) => {
        setErrorMsg(true)
        setLoading(false)
      })
  }

  const handleChange = (otp) => {
    if (errorMsg) setErrorMsg(false)
    if (inputErrorMsg) setInputErrorMsg(false)
    setOtp(otp)
  }

  const checkUser = async (phoneNumber, idToken) => {
    const lat = localStorage.getItem('lat') || LATITUDE
    const long = localStorage.getItem('long') || LONGITUDE

    if (userType === 'new-user') {
      console.log('New Guest User')
      const xmppID = Math.floor(100000 + Math.random() * 900000)
      const data = {
        login: '+91' + phoneNumber,
        password: 'costBog4+91' + phoneNumber,
        firstName: 'Guest',
        lastName: '',
        email: 'guest-+91' + phoneNumber + '@costbo.com',
        phone: '+91' + phoneNumber,
        type: '0',
        imeiNumber: '',
        deviceModel: 'WEB',
        osVersion: 'Mac',
        deviceID: 'mac-os-x-15-Chrome',
        clientVersion: '',
        latitude: lat,
        longitude: long,
        gender: 'M/F',
        birthYear: '0',
        fcmKey: '',
        apnsKey: '',
        fcmUserToken: idToken,
        id: '',
        imageUrl: '',
        status: '',
        sr: true,
        country: 'INDIA',
        xmppID: xmppID,
      }

      createGuestUser(data).then((res) => {
        console.log('CREATE_GUEST_USER_RESPONSE', res)
        let formdata = new FormData()
        formdata.append('grant_type', 'password')
        formdata.append('username', '+91' + phoneNumber)
        formdata.append('password', idToken)
        userAuthGuestUser(formdata).then((res1) => {
          console.log('USER_AUTH_RESPONSE', res1)
          getOwnRewards('91' + phoneNumber).then((resp1) => {
            upadateRewardProfile({
              data: resp1?.data?.details,
              phone: '91' + phoneNumber,
            }).then(() => {
              setLoading(false)
              // onLoginSuccess()
            })
          })
          setLoading(false)
          sessionStorage.setItem('userType', 'guest')
          onLoginSuccess()
        })
      })
    } else {
      console.log('Returned Guest User')
      let formdata = new FormData()
      formdata.append('grant_type', 'password')
      formdata.append('username', '+91' + phoneNumber)
      formdata.append('password', idToken)
      userAuthGuestUser(formdata).then((res) => {
        console.log('USER_AUTH_RESPONSE', res)
        setLoading(false)
        sessionStorage.setItem('userType', 'guest')
        onLoginSuccess()
      })
    }

    // return dispatch(checkExistingUserGuest(phoneNumber)).then((response) => {
    //   console.log(response)
    //   if (response.payload) {
    //     if (response.payload.status === 200) {
    //       if (response.payload.data.sr === true) {
    //         if (response.payload.data.na === false) {
    //           console.log('New Guest User')
    //           const xmppID = Math.floor(100000 + Math.random() * 900000)
    //           const data = {
    //             login: '+91' + phoneNumber,
    //             password: 'costBog4+91' + phoneNumber,
    //             firstName: 'Guest',
    //             lastName: '',
    //             email: 'guest-+91' + phoneNumber + '@costbo.com',
    //             phone: '+91' + phoneNumber,
    //             type: '0',
    //             imeiNumber: '',
    //             deviceModel: 'WEB',
    //             osVersion: 'Mac',
    //             deviceID: 'mac-os-x-15-Chrome',
    //             clientVersion: '',
    //             latitude: lat,
    //             longitude: long,
    //             gender: 'M/F',
    //             birthYear: '0',
    //             fcmKey: '',
    //             apnsKey: '',
    //             fcmUserToken: idToken,
    //             id: '',
    //             imageUrl: '',
    //             status: '',
    //             sr: true,
    //             country: 'INDIA',
    //             xmppID: xmppID,
    //           }

    //           createGuestUser(data).then((res) => {
    //             console.log('CREATE_GUEST_USER_RESPONSE', res)
    //             let formdata = new FormData()
    //             formdata.append('grant_type', 'password')
    //             formdata.append('username', '+91' + phoneNumber)
    //             formdata.append('password', idToken)
    //             userAuthGuestUser(formdata).then((res1) => {
    //               console.log('USER_AUTH_RESPONSE', res1)
    //               setLoading(false)
    //               onLoginSuccess()
    //             })
    //           })
    //           console.log('New Guest User', data)
    //         } else {
    //           console.log('Returned Guest User')
    //           let formdata = new FormData()
    //           formdata.append('grant_type', 'password')
    //           formdata.append('username', '+91' + phoneNumber)
    //           formdata.append('password', idToken)
    //           userAuthGuestUser(formdata).then((res) => {
    //             console.log('USER_AUTH_RESPONSE', res)
    //             setLoading(false)
    //             onLoginSuccess()
    //           })
    //         }
    //       }
    //     }
    //   }
    // })
  }
  return (
    <Box px={2} pt={2}>
      <Typography
        // variant='h5'
        component='h3'
        color={headingColor}
        fontSize={{ xs: 14 }}
        fontWeight={500}
        textAlign='center'
        lineHeight={1.5}
        pb={{ xs: 0, md: 1.1 }}
      >
        Enter the OTP sent to your mobile number
      </Typography>
      <Box
        display={'flex'}
        alignItems='center'
        justifyContent={'center'}
        gap={0.8}
        mb={2}
      >
        <Typography
          // variant='h5'
          component='h3'
          color={headingColor}
          fontSize={{ xs: 15 }}
          fontWeight={600}
          textAlign='center'
          lineHeight={1.5}
          pb={{ xs: 0, md: 2 }}
        >
          +91 {mobileNumber}
        </Typography>
        <Typography
          // variant='h5'
          component='h6'
          color={loginPrimary}
          fontSize={{ xs: 13 }}
          fontWeight={500}
          textAlign='center'
          lineHeight={1.5}
          sx={{
            textDecoration: 'underline',
            '&:hover': { textDecoration: 'none' },
            cursor: 'pointer',
          }}
          pb={{ xs: 0, md: 2 }}
          onClick={onChangeNumber}
        >
          Change
        </Typography>
      </Box>

      <Box sx={{ px: { md: 4, xs: 1 }, my: 3.5 }}>
        <OtpInput
          value={otp}
          onChange={handleChange}
          name='otp'
          required
          isInputNum={true}
          numInputs={6}
          shouldAutoFocus={true}
          inputStyle={{
            borderBottom: borderOTPTextbox,
            width: '30px',
            color: headingColor,
          }}
          containerStyle={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '20px',
          }}
        />
      </Box>
      {errorMsg && (
        <Typography
          variant='subtitle1'
          component='p'
          fontSize={13}
          color={errorTextColor}
        >
          {inputError_OTPIncorrent}
        </Typography>
      )}
      {inputErrorMsg && (
        <Typography
          variant='subtitle1'
          component='p'
          fontSize={13}
          color={errorTextColor}
        >
          Please Enter the 6 digits OTP
        </Typography>
      )}

      <Box>
        <Typography
          variant='body1'
          component='p'
          color={stepperIconColor}
          fontSize={12}
          display='inline'
        >
          By continuing, you agree to our{' '}
          <Typography
            color={loginPrimary}
            display='inline'
            fontSize={12}
            fontWeight={500}
            component={Link}
            // target='_blank'
            to={`/termsAndConditions`}
          >
            Terms of Use
          </Typography>{' '}
          &{' '}
          <Typography
            color={loginPrimary}
            display='inline'
            fontSize={12}
            fontWeight={500}
            component={Link}
            // target='_blank'
            to={`/privacyPolicy`}
          >
            Privacy Policy
          </Typography>
        </Typography>
      </Box>

      <Button
        sx={{
          ...styles.actionBtn(),
          border: loading ? 'none' : `1px solid ${secondaryColor}`,
          // border: loading ? 'none' : `1px solid ${loginPrimary}`,
        }}
        variant='contained'
        fullWidth
        disableElevation
        size='small'
        type='button'
        //   onClick={handleSubmit}
        onClick={() => onSignInSubmit('guest')}
        disabled={loading}
      >
        Login As Guest
      </Button>
      <Divider sx={{ mb: 1.5, mt: 2.5 }}>OR</Divider>
      <Typography
        // variant='h5'
        component='h6'
        color={loginPrimary}
        fontSize={{ xs: 15 }}
        fontWeight={500}
        textAlign='center'
        lineHeight={1.5}
        sx={{
          textDecoration: 'underline',
          '&:hover': { textDecoration: 'none' },
          cursor: 'pointer',
        }}
        onClick={() => onSignInSubmit('create')}
        pb={{ xs: 0, md: 2 }}
      >
        Create an account
      </Typography>
    </Box>
  )
}

export default OTPScreen

const styles = {
  actionBtn: () => ({
    fontSize: '15px',
    fontWeight: 500,
    color: whiteColor,
    p: '3px 20px',
    mt: '15px !important',
    border: '1px solid ' + secondaryColor,
    backgroundColor: secondaryColor,
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: whiteColor,
      color: secondaryColor,
    },
    letterSpacing: '0.5px',
  }),
}

import { StarRounded } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Rating,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material'
import { useState } from 'react'
import { addReview } from '../../../services/api_calls/review_api/reviewApi'
import moment from 'moment'
import {
  arrowBgColor,
  backgroundColor,
  borderTextbox,
  labelColor,
  whiteColor,
} from '../../../configs/styles/muiThemes'
import AlertDialog from 'components/AlertDialog'
import { getProfileId, getUserDetails } from 'utils/authHelpers'
import { allowOnlyEnglish } from 'utils/utilities'

function AddReview(props) {
  const [reviewRating, setReviewRating] = useState(0)
  const [reviewTitle, setReviewTitle] = useState('')
  const [message, setMessage] = useState('')
  const [errorDialog, setErrorDialog] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')
  const [loading, setLoading] = useState(false)

  const handleTitleChange = (event) => {
    const finalValue = allowOnlyEnglish(event.target.value)
    setReviewTitle(finalValue)
  }

  const handleDescriptionChange = (event) => {
    const finalValue = allowOnlyEnglish(event.target.value)
    setMessage(finalValue)
  }

  const businessId = sessionStorage.getItem('businessId')
  const profileId = getProfileId()
  const userData = getUserDetails()

  const addReviewData = async () => {
    if (loading) return
    console.log('REVIEW_TEST_HIT')

    if (reviewTitle.length > 200) {
      setErrorMsg('Title should not be more than 200 characters.')
      setErrorDialog(true)
      return
    }

    if (message.length > 5000) {
      setErrorMsg('Description should not be more than 5000 characters.')
      setErrorDialog(true)
      return
    }

    if (reviewTitle.length < 10 || message.length < 10) {
      setErrorMsg('Title and description should be of minimum 10 characters.')
      setErrorDialog(true)
      return
    }

    if (reviewRating <= 0) {
      setErrorMsg('Your Rating should not be empty   ')
      setErrorDialog(true)
      return
    }

    setLoading(true)

    if (reviewRating > 0 && reviewTitle.length > 10 && message.length > 10) {
      const data = {
        profileId: profileId,
        name: userData?.account?.name,
        businessId: businessId,
        productId: '',
        businessName: '',
        haveBought: true,
        eligibility: '',
        latitude: 0.0,
        longitude: 0.0,
        reviewRating,
        reviewDetails: [
          {
            reviewTitle,
            reviewRating,
            message,
            messageCreateDate: moment().format('MMM-DD-YYYY'),
          },
        ],
      }

      addReview(data)
        .then((res) => {
          props.getOwnReviewDetails()
          props.getSummary()

          setTimeout(() => {
            setLoading(false)
          }, 3000)
        })
        .catch((err) => {
          setLoading(false)
        })
    }
  }

  return (
    <Box sx={props.styles}>
      <Box sx={{ mt: 3, mb: 1, display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1' sx={styles.label}>
          Your Rating
        </Typography>

        <Rating
          icon={<StarRounded sx={{ color: 'primary.main' }} />}
          emptyIcon={<StarRounded sx={{ color: arrowBgColor }} />}
          precision={1}
          value={reviewRating}
          onChange={(event) => {
            setReviewRating(parseInt(event.target.value, 10))
          }}
          sx={{ ml: 1 }}
        />
      </Box>

      <Box sx={{ my: 1 }}>
        <Typography variant='body1' sx={styles.label}>
          Title{' '}
        </Typography>
        <Typography variant='body1' sx={[styles.label, { color: '#f00' }]}>
          *
        </Typography>
      </Box>

      <TextField
        value={reviewTitle}
        variant='standard'
        sx={styles.inputBox(props.isUpdate ? '#fff' : '#f4f6fa')}
        fullWidth
        InputProps={{
          disableUnderline: true,
        }}
        placeholder='(Min 10 characters, Max 200 characters)'
        textProps={{
          error: reviewTitle.length < 10 || reviewTitle.length > 200,
          helperText:
            reviewTitle.length < 10 || reviewTitle.length > 200
              ? 'Min 10 characters, Max 200 characters'
              : '',
        }}
        onChange={handleTitleChange}
      />

      <Box sx={{ my: 1 }}>
        <Typography variant='body1' sx={styles.label}>
          Description{' '}
        </Typography>
        <Typography variant='body1' sx={[styles.label, { color: '#f00' }]}>
          *
        </Typography>
      </Box>

      {/* <TextField
        value={message}
        variant='standard'
        sx={styles.inputBox(props.isUpdate ? '#fff' : '#f4f6fa')}
        fullWidth
        multiline
        InputProps={{
          disableUnderline: true,
          maxRows: 4,
          rows: 3,
        }}
        placeholder='(Min 10 chracters, Max: 5000 characters)'
        onChange={(event, newValue) => {
          handleDescriptionChange(event)
        }}
        // textProps={{

        //   onChange: (title) => {
        //     handleDescriptionChange(title)
        //   },
        // }}
      /> */}

      <TextareaAutosize
        minRows={3}
        aria-label='maximum height'
        style={styles.inputBox(props?.isUpdate ? whiteColor : backgroundColor)}
        placeholder='(Min 10 chracters, Max 5000 characters)'
        value={message}
        onChange={(event, newValue) => {
          handleDescriptionChange(event)
        }}
      />

      <Box sx={styles.btnContainer}>
        <Button
          sx={[styles.submitBtn, { ml: 3 }]}
          variant='contained'
          disableElevation
          size='small'
          onClick={() => addReviewData()}
        >
          {loading ? (
            <CircularProgress sx={{ color: whiteColor, mr: 1 }} size={16} />
          ) : null}{' '}
          {props.isUpdate ? 'Update' : 'Submit Review'}
        </Button>
      </Box>

      <AlertDialog
        content={errorMsg}
        isOpen={errorDialog}
        onClose={() => {
          setErrorDialog(false)
        }}
      />
    </Box>
  )
}

export default AddReview

const styles = {
  label: {
    fontSize: '15px',
    color: labelColor,
    fontWeight: 400,
    display: 'inline',
  },

  inputBox: (color) => ({
    border: borderTextbox,
    borderRadius: '6px',
    fontSize: '13px',
    fontFamily: 'Poppins',
    outline: 'none',
    color: '#777',
    background: color,
    padding: '8px 10px',
    width: '100%',
  }),

  infolabel: {
    display: 'block',
    fontSize: '13px',
    textAlign: 'right',
    py: '8px',
    color: '#9e9fa3',
  },

  btnContainer: { display: 'flex', justifyContent: 'flex-end', mt: 1 },

  submitBtn: {
    fontSize: '14px',
    fontWeight: 500,
    color: whiteColor,
    p: '7px 28px',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: 'primary.main',
    },
  },
}

import { Typography, Box } from '@mui/material'
import {
  copyrightTextColor,
  footerCopyrightBGColor,
  trackColor,
  whiteColor,
} from 'configs/styles/muiThemes'
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

function LandingPageFooter() {
  let businessFullName = sessionStorage.getItem('businessFullName')

  return (
    <Box mt={3}>
      <Box
        sx={{
          backgroundColor: trackColor,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 1,
          px: 3,
        }}
      >
        <Box
          component='img'
          src='https://storage.googleapis.com/bodefaults/shopweb/logo_footer.png'
          alt={businessFullName + ' CostBo'}
          width={{ xs: '100px', md: '122px' }}
          height='auto'
          ml={2}
        />

        <Typography
          variant='body2'
          color={whiteColor}
          fontWeight={500}
          fontSize={{ xs: 14, md: 16 }}
        >
          Happy Shopping!
        </Typography>
      </Box>

      <Box
        display={'flex'}
        justifyContent='space-between'
        alignItems='center'
        flexDirection={{ md: 'row', xs: 'column' }}
        gap={{ xs: 1, md: 2 }}
        my={1.8}
        px={3}
      >
        <Box display='flex' alignItems='center'>
          <Typography
            fontSize={{ xs: 13, md: 14 }}
            fontWeight={500}
            color={copyrightTextColor}
            textAlign='center'
            ml={2}
          >
            Any questions/clarifications, we are here to help!
          </Typography>
        </Box>

        <Box
          display='flex'
          alignItems='center'
          flexDirection={{ md: 'row', xs: 'column' }}
          justifyContent='center'
          gap={1}
        >
          <Box display='flex' alignItems='center' mr={{ xs: 0, md: 3 }}>
            <EmailOutlinedIcon sx={{ fontSize: '20px', color: copyrightTextColor }} />
            <Typography
              fontSize={{ xs: 13, md: 14 }}
              fontWeight={500}
              color={copyrightTextColor}
              ml={1.5}
              component='a'
              href='https://mail.google.com/mail/u/0/?fs=1&to=support@costbo.com&tf=cm'
              target='_blank'
            >
              support@costbo.com
            </Typography>
          </Box>
          <Box display='flex' alignItems='center'>
            <WhatsAppIcon
              sx={{ fontSize: '20px', color: copyrightTextColor }}
            />
            <Typography
              fontSize={{ xs: 13, md: 14 }}
              fontWeight={500}
              color={copyrightTextColor}
              ml={1.3}
              component='a'
              href='https://api.whatsapp.com/send?phone=918088422611'
              target='_blank'
            >
              +91 8088422611
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box py={1.8} backgroundColor={footerCopyrightBGColor}>
        <Typography
          variant='body1'
          fontSize={13}
          fontWeight={500}
          color={copyrightTextColor}
          textAlign='center'
        >
          © 2022 CostBo Services Private Ltd, All Rights Reserved.
        </Typography>
      </Box>
    </Box>
  )
}

export default LandingPageFooter

import axios from 'axios'
import { ADD_TIMELINE_SHARE_URL } from 'services/constant'
import { getProfileId } from 'utils/authHelpers'
import { getApiInstance } from '../../ApiInstance'

export const getActiveBusinessDetails = async () => {
  const businessId = sessionStorage.getItem('businessId')
  const instance = getApiInstance()
  try {
    const response = await instance.get(
      `business-query-side/api/v1/business/getBusinessInfo?params=${businessId}`
    )
    if (response.data) {
      localStorage.setItem(
        'businessInfo',
        JSON.stringify(response.data?.businessInfo)
      )
      sessionStorage.setItem(
        'businessInfo',
        JSON.stringify(response.data?.businessInfo)
      )
      sessionStorage.setItem(
        'businessFullName',
        response.data?.businessInfo?.[0]?.businessName
          ?.split(' ')
          .slice(0, 2)
          .join(' ')
      )
    }
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getReviewSummary = async () => {
  const businessId = sessionStorage.getItem('businessId')
  const instance = getApiInstance()
  try {
    const response = await instance.get(
      `review-query-side/api/v1/review/webGetReviewSummary?params=${businessId}`
    )
    if (response.data) {
      sessionStorage.setItem('reviewSummary', JSON.stringify(response.data))
    }
    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getSocialActivity = async () => {
  const businessId = sessionStorage.getItem('businessId')
  const instance = getApiInstance()
  try {
    const response = await instance.get(
      `businesssocial-query-side/api/v1/businessSocial/getBusinessFollowerCount?businessId=${businessId}`
    )
    if (response.data) {
      sessionStorage.setItem('socialActivity', JSON.stringify(response.data))
    }

    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const getTotalViews = async () => {
  const businessId = sessionStorage.getItem('businessId')
  const instance = getApiInstance()
  try {
    const response = await instance.get(
      `activity-query-side/api/v1/activity/viewBusinessActivity?businessId=${businessId}&activityName=viewActiveBusiness`
    )
    if (response.data) {
      sessionStorage.setItem('totalViews', JSON.stringify(response.data))
    }

    return response.data
  } catch (error) {
    console.log(error)
  }
}
export const getIpAddress = async () => {
  const instance = getApiInstance()
  try {
    const response = await axios.get(`https://jsonip.com`)

    return response.data
  } catch (error) {
    console.log(error)
  }
}
export const getLocation = async () => {
  const instance = getApiInstance()
  try {
    // const response = await instance.get(`http://ip-api.com/json`)
    const response = await axios.get(`https://ipapi.co/json`)

    return response.data
  } catch (error) {
    console.log(error)
  }
}
export const addTimelineShare = async (data) => {
  const timeLineId = sessionStorage.getItem('businessId')
  const instance = getApiInstance()

  try {
    // const response = await instance.get(`http://ip-api.com/json`)
    const response = await instance.post(ADD_TIMELINE_SHARE_URL, data, {
      headers: { timeLineId },
    })

    return response.data
  } catch (error) {
    console.log(error)
  }
}

export const updateFollow = async (data) => {
  const businessId = sessionStorage.getItem('businessId')
  const businessSocialId = sessionStorage.getItem('businessSocialId')
  const profileId = getProfileId()

  const instance = getApiInstance()
  let reqData = null
  if (data === 0) {
    reqData = {
      businessSocialId,
      status: 0,
    }
  } else {
    reqData = {
      businessId,
      profileId,
      status: 1,
    }
  }
  try {
    const response = await instance.post(
      `businesssocial-command-side/api/v1/businessSocial/businessFollowOrUnFollow`,
      reqData
    )
    // if (response.data) {
    //   sessionStorage.setItem('totalViews', JSON.stringify(response.data))
    // }

    return response.data
  } catch (error) {
    console.log(error)
  }
}

import { GET_BUSINESS_PRODUCT_CATEGORY } from "services/constant";
import { getApiInstance } from "../../ApiInstance";

export const getCategoryDetailsByBusiness = async () => {
  const instance = getApiInstance({
    
   });
  try {
    const response = await instance(
      `${GET_BUSINESS_PRODUCT_CATEGORY}/product/getCategoryDetails/38bd76c370274724ba9feaa032266ee4`
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

import { StarRounded } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  Rating,
  TextareaAutosize,
  TextField,
  Typography,
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
  addReview,
  editReview,
} from '../../services/api_calls/review_api/reviewApi'
import moment from 'moment'
import {
  arrowBgColor,
  backgroundColor,
  borderTextbox,
  labelColor,
  whiteColor,
} from '../../configs/styles/muiThemes'
import AlertDialog from 'components/AlertDialog'
import { allowOnlyEnglish } from 'utils/utilities'

/**
 * Edit Existing Personal Reviews
 * @param styles
 * @param isUpdate
 * @param ownReview
 * @param onClose
 * @param getOwnReviewDetails
 * @param getSummary
 *  @author Rupesh
 */
function EditReviewForm(props) {
  const [reviewRating, setReviewRating] = useState(
    props.ownReview ? props.ownReview.reviewRating || 0 : 0
  )
  const [reviewTitle, setReviewTitle] = useState(
    props.ownReview.reviewRating || ''
  )
  const [message, setMessage] = useState(props.ownReview.reviewRating || '')
  const [errorDialog, setErrorDialog] = useState(false)
  const [errorMsg, setErrorMsg] = useState('')

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setMessage(props.ownReview.message || '')
    setReviewRating(props.ownReview.reviewRating || 0)
    setReviewTitle(props.ownReview.reviewTitle || '')
  }, [props])

  const handleTitleChange = (event) => {
    const finalValue = allowOnlyEnglish(event.target.value)
    setReviewTitle(finalValue)
  }
  const handleDescriptionChange = (event) => {
    const finalValue = allowOnlyEnglish(event.target.value)
    setMessage(finalValue)
  }

  /**
   * Save Edited Review
   * @author Rupesh
   */
  const editReviewData = async () => {
    if (loading) return
    console.log('EDIT_HIT', reviewTitle, '--', message)

    if (reviewTitle.length > 200) {
      setErrorMsg('Title should not be more than 200 characters.')
      setErrorDialog(true)
      return
    }

    if (message.length > 5000) {
      setErrorMsg('Description should not be more than 5000 characters.')
      setErrorDialog(true)
      return
    }

    if (reviewTitle.length < 10 || message.length < 10) {
      setErrorMsg('Title and description should be of minimum 10 characters.')
      setErrorDialog(true)
      return
    }

    if (reviewRating <= 0) {
      setErrorMsg('Your Rating should not be empty   ')
      setErrorDialog(true)
      return
    }

    setLoading(true)

    if (reviewRating > 0 && reviewTitle.length > 10 && message.length > 10) {
      const data = {
        reviewRating,
        reviewDetails: [
          {
            reviewTitle,
            reviewRating,
            message,
            messageCreateDate: moment().format('MMM-DD-YYYY'),
          },
        ],
      }

      editReview(data, props.ownReview.id)
        .then((res) => {
          props.getOwnReviewDetails()
          props.getSummary()
          props.onClose()

          setTimeout(() => {
            setLoading(false)
          }, 3000)
        })
        .catch((err) => {
          setLoading(false)
        })
    }
    // else{
    //   setErrorDialog(true)
    // }
  }
  return (
    <Box sx={props.styles}>
      <Box sx={{ mb: 1, display: 'flex', alignItems: 'center' }}>
        <Typography variant='body1' sx={styles.label}>
          Your Rating
        </Typography>

        <Rating
          icon={<StarRounded sx={{ color: 'primary.main' }} />}
          emptyIcon={<StarRounded sx={{ color: arrowBgColor }} />}
          precision={1}
          value={reviewRating}
          onChange={(event) => {
            setReviewRating(parseInt(event.target.value, 10))
          }}
          sx={{ ml: 1 }}
        />
      </Box>

      <Box sx={{ my: 1 }}>
        <Typography variant='body1' sx={styles.label}>
          Title{' '}
        </Typography>
        <Typography variant='body1' sx={[styles.label, { color: '#f00' }]}>
          *
        </Typography>
      </Box>

      <Box>
        <TextField
          value={reviewTitle}
          variant='standard'
          sx={styles.inputBox(props?.isUpdate ? whiteColor : backgroundColor)}
          fullWidth
          InputProps={{
            disableUnderline: true,
          }}
          textProps={{
            error: reviewTitle.length < 10 || reviewTitle.length > 200,
            helperText:
              reviewTitle.length < 10 || reviewTitle.length > 200
                ? 'Min 10 characters, Max 200 characters'
                : '',
            value: reviewTitle,

            onChange: (title) => {
              handleTitleChange(title)
            },
          }}
          placeholder='(Min 10 characters, Max 200 characters)'
          onChange={(event, newValue) => {
            handleTitleChange(event)
          }}
        />

        <Box sx={{ my: 1 }}>
          <Typography variant='body1' sx={styles.label}>
            Description{' '}
          </Typography>
          <Typography variant='body1' sx={[styles.label, { color: '#f00' }]}>
            *
          </Typography>
        </Box>

        <TextareaAutosize
          minRows={3}
          aria-label='maximum height'
          style={styles.inputBox(
            props?.isUpdate ? whiteColor : backgroundColor
          )}
          placeholder='(Min 10 chracters, Max 5000 characters)'
          value={message}
          onChange={(event, newValue) => {
            handleDescriptionChange(event)
          }}
        />
      </Box>

      <Box sx={styles.btnContainer}>
        <Button
          sx={[styles.submitBtn, { ml: 3 }]}
          variant='contained'
          disableElevation
          size='small'
          onClick={() => (props.isUpdate ? editReviewData() : addReview())}
        >
          {loading ? (
            <CircularProgress sx={{ color: whiteColor, mr: 1 }} size={16} />
          ) : null}{' '}
          {props.isUpdate ? 'Update' : 'Submit Review'}
        </Button>
      </Box>

      <AlertDialog
        content={errorMsg}
        isOpen={errorDialog}
        onClose={() => {
          setErrorDialog(false)
        }}
      />
    </Box>
  )
}

export default EditReviewForm

const styles = {
  label: {
    fontSize: '15px',
    color: labelColor,
    fontWeight: 400,
    display: 'inline',
  },

  inputBox: (color) => ({
    width:'calc(100% - 20px)',
    border: borderTextbox,
    borderRadius: '6px',
    fontSize: '13px',
    fontFamily: 'Poppins',
    outline:'none',
    color: '#777',
    background: color,
    padding: '8px 10px',
  }),

  infolabel: {
    display: 'block',
    fontSize: '13px',
    textAlign: 'right',
    py: '8px',
    color: '#9e9fa3',
  },

  btnContainer: { display: 'flex', justifyContent: 'flex-end', mt: 1 },

  submitBtn: {
    fontSize: '14px',
    fontWeight: 500,
    color: whiteColor,
    p: '7px 28px',
    borderRadius: '6px',
    '&:hover': {
      backgroundColor: 'primary.main',
    },
  },
}

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
    getAllReviewData,
    getCostboContactCounts,
    getOwnReviewData,
    getProfileImages,
    getReviewSummaryData,
    getTotalReviewCount,
    helpFulReview
} from "../../services/api_calls/review_api/reviewApi";

const initialState = {
    reviewSummary: [],
    allReviews: null,
    ownReview: null,
    connectionCount: [],
    reviewCount: [],
    profileImages: [],
    loading: false,

    // Loaders
    allReviewDetailsLoading: false
};

//Fetching Review Summary
export const getReviewSummaryDetails = createAsyncThunk("getReviewSummaryDetails", async (thunkAPI) => {
    
    const res = await getReviewSummaryData();
    return res;
});

//Fetching all customer reviews
export const getAllReviewDetails = createAsyncThunk("getAllReviewDetails", async (param,thunkAPI) => {
    const res = await getAllReviewData(param);
    return res;
});

//Fetching Own reviews
export const getOwnReviewDetails = createAsyncThunk("getOwnReviewDetails", async (thunkAPI) => {
    const res = await getOwnReviewData();
    return res;
});

//Fetching All Connection Count
export const getAllConnections = createAsyncThunk("getAllConnections", async (formdata) => {
   
    const res = await getCostboContactCounts(formdata);
    return res;
});

//Fetching All Review Count
export const getAllReviewCount = createAsyncThunk("getAllReviewCount", async (formdata) => {
    const res = await getTotalReviewCount(formdata);
    return res;
});

//Fetching All Profile Images
export const getAllProfileImages = createAsyncThunk("getAllProfileImages", async (formdata) => {
    const res = await getProfileImages(formdata);
    return res;
});

//Update helpfull review count
export const helpFullReviewPost = createAsyncThunk("helpFullReviewPost", async (reviewId,profileId) => {
    const res = await helpFulReview(reviewId,profileId);
    return res;
});

export const customerReviewSlice = createSlice({
    name: "reviewCustomer",
    initialState,
    reducers: {},
    extraReducers: {
        //Review Summary Reducer
        [getReviewSummaryDetails.pending]: (state) => {
            state.loading = true;
        },
        [getReviewSummaryDetails.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.reviewSummary = payload?.reviewSummary[0];
        },
        [getReviewSummaryDetails.rejected]: (state) => {
            state.loading = false;
        },

        //all customer reviews reducer
        [getAllReviewDetails.pending]: (state) => {
            state.allReviewDetailsLoading = true;
            state.loading = true;
        },
        [getAllReviewDetails.fulfilled]: (state, { payload }) => {
            state.allReviewDetailsLoading = false;
            state.loading = false;
            state.allReviews = payload;
        },
        [getAllReviewDetails.rejected]: (state) => {
            state.allReviewDetailsLoading = false;
            state.loading = false;
        },

        //Own reviews reducer
        [getOwnReviewDetails.pending]: (state) => {
            state.loading = true;
        },
        [getOwnReviewDetails.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.ownReview = payload?.data?.ownReview;
        },
        [getOwnReviewDetails.rejected]: (state) => {
            state.loading = false;
        },

        //Connection Count reducer
        [getAllConnections.pending]: (state) => {
            state.loading = true;
        },
        [getAllConnections.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.connectionCount = payload?.data?.connectionList;
        },
        [getAllConnections.rejected]: (state) => {
            state.loading = false;
        },

        //All Review Count reducer
        [getAllReviewCount.pending]: (state) => {
            state.loading = true;
        },
        [getAllReviewCount.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.reviewCount = payload?.data?.totalReviewList;
        },
        [getAllReviewCount.rejected]: (state) => {
            state.loading = false;
        },

        //All Profile Images reducer
        [getAllProfileImages.pending]: (state) => {
            state.loading = true;
        },
        [getAllProfileImages.fulfilled]: (state, { payload }) => {
            state.loading = false;
            state.profileImages = payload?.data?.accountList;
        },
        [getAllProfileImages.rejected]: (state) => {
            state.loading = false;
        },

        //Update isHelpfull count
        [helpFullReviewPost.pending]: (state) => {
            state.loading = true;
        },
        [helpFullReviewPost.fulfilled]: (state, { payload }) => {
            state.loading = false;
        },
        [helpFullReviewPost.rejected]: (state) => {
            state.loading = false;
        },
    },
});

export const customerReviewReducer = customerReviewSlice.reducer;

import { ThemeProvider } from '@mui/material'

import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from './App'
import theme from './configs/styles/muiThemes'
import store from './redux/store'
import { getUrlPathParameter, setSessionStorage } from 'utils/urlHelper'
import { CookiesProvider } from 'react-cookie';


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
      <CookiesProvider>
        <App />
        </CookiesProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)
